:root {
  --dark: #271B80;
  --medium: #5061C5;
  --light: #C9D5FD;
  --yellowish: #F3D408;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, .navi-tabs {
  color: var(--medium);
}

.main-heading {
  color: var(--dark);
  position: relative;
}

.main-heading::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: var(--light);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.main-heading:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.heading {
  background-color: var(--dark);
  color: white;
  width: 100%;
  padding: 10px;
}

.subheading {
  background-color: var(--medium);
  color: white;
  width: 100%;
  padding: 8px;
}

.subsubheading {
  background-color: var(--light);
  width: 100%;
  padding: 6px;
}

.button {
  background-color: var(--medium);
  padding: 10px 20px;
  color: white;
  border-radius: 7px;
  text-decoration: none;
}

.button:hover {
  background-color: var(--dark);
  box-shadow: 3px 3px 3px 0 var(--light);
}

.payment-card {
  width: 30%;
}

.tariff {
  text-transform: uppercase;
  color: var(--dark);
  font-weight: bold;
}

.email, .dark-text {
  color: var(--dark);
}

.accent {
  color: var(--medium);
  font-weight: bold;
}

.alpha-carousel .carousel-inner {
  height: 350px;
  display: flex;
  align-items: center;
}

.animation {
  text-transform: uppercase;
	font-weight: 700;
	background: linear-gradient(145deg, var(--dark) 10%, var(--medium) 40%, var(--light) 50%, var(--medium) 60%, var(--dark) 90%);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	animation: wave 3s linear infinite;
	display: inline-block;
}

@keyframes wave {
	to {
		background-position: -200% center;
	}
}

@media only screen and (max-width: 768px) {
  .payment-div {
    flex-direction: column;
    align-items: center;
  }
  .payment-card {
    width: 90%;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .about {
    flex-direction: column;
    align-items: center;
  }
  .avatar-div {
    margin-bottom: 15px;
  }
}

.shimmer {
  background: linear-gradient(to right, var(--light) 0, var(--medium) 10%, var(--dark) 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmering 3s infinite linear;
}

@keyframes shimmering {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 275px;
  }
  100% {
    background-position: 550px;
  }
}